import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import UserIcon from '@material-ui/icons/People';
import DeleteIcon from '@material-ui/icons/Delete';
import { UserList, UserShow, UserCreate, UserEdit } from "./users";
import { DeletedUserList } from "./deletedUsers";
import {
  FirebaseAuthProvider,
  // FirebaseDataProvider,
  // FirebaseRealTimeSaga
} from 'react-admin-firebase';
import firebaseDataProvider from './ra-data-firebase-client-dfiav'
import firebase from 'firebase/app'
import "firebase/database";

import Dashboard from './Dashboard';

// import { VersionsList } from "./versions";

// smmd firebase
// const config = {
//   apiKey: "AIzaSyA3agW2DcosnJkv8mTVGBIn_Qlo2J7DZ4E",
//   authDomain: "dfiav-fd18e.firebaseapp.com",
//   databaseURL: "https://dfiav-fd18e-default-rtdb.europe-west1.firebasedatabase.app", // "https://dfiav-fd18e-dev.europe-west1.firebasedatabase.app", // 
//   projectId: "dfiav-fd18e",
//   storageBucket: "dfiav-fd18e.appspot.com",
//   messagingSenderId: "314977144708",
//   appId: "1:314977144708:web:e0ff2b9ca16665ca2b57d1",
//   measurementId: "G-94NZDGH00G",
// };

// DFIAV firebase
const config = {
  apiKey: "AIzaSyBpLioAGWLywAuKlUi9xz-nK3c1N5gAB54",
  authDomain: "dfiav-app.firebaseapp.com",
  databaseURL: "https://dfiav-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dfiav-app",
  storageBucket: "dfiav-app.firebasestorage.app",
  messagingSenderId: "328661133952",
  appId: "1:328661133952:web:7abba3dc5525cde4d39465",
  measurementId: "G-2MJ08BCD0W"
};

firebase.initializeApp(config)

const options = {
  logging: true,
  // rootRef: 'root_collection/some_document',
  // watch: ['users'],
  persistence: 'session',
  disableMeta: true,
  renameMetaFields: {

    created_at: 'created', // default: 'createdate'
    // created_by: 'my_created_by', // default: 'createdby'
    // updated_at: 'my_updated_at', // default: 'lastupdate'
    // updated_by: 'my_updated_by', // default: 'updatedby'
  },
  // lazyLoading: {
  //   enabled: true
  // },
  // firestoreCostsLogger: {
  //   enabled: true,
  //   // localStoragePrefix // optional
  // }
};

const settings = { context: '', imagekey: "images", filekey: "files" }

// const dataProvider = FirebaseDataProvider(config, options);
const dataProvider = firebaseDataProvider(firebase, settings);
const authProvider = FirebaseAuthProvider(config, options);

// const user = await authProvider.checkAuth()
// console.log(user);

const App = () => <Admin
  dataProvider={dataProvider}
  authProvider={authProvider}
  dashboard={Dashboard}
  disableTelemetry
>
  <Resource name="users" list={UserList} show={UserShow} create={UserCreate} edit={UserEdit} icon={UserIcon} options={{ label: 'Benutzer' }} />
  <Resource name="deletedUsers" list={DeletedUserList} show={UserShow} create={UserCreate} edit={UserEdit} icon={DeleteIcon} options={{ label: 'Gelöscht' }} />
  {/* <Resource name="currentAppVersionWeb" list={VersionsList}/> */}
</Admin>;

export default App;
